<template>
  <div class="row mt-3 h-100">
    <div class="mb-4" :class="getColumn" v-for="index in 4" :key="index">
      <div class="quizMainDev" style="background: #ffffff">
        <b-skeleton-img height="220px"></b-skeleton-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getColumn() {
      let width = window.innerWidth;

      return width > 1500 ? "col-12 col-md-6 col-lg-4" : "col-12 col-md-6";
    },
  },
};
</script>
